import { Button } from "@chakra-ui/react";

import { useAuthContext } from "~web/state/auth";

import LoggedInAccount from "./LoggedInAccount";

const SignIn: React.FC = () => {
  const { user } = useAuthContext();
  const { loginModal } = useAuthContext();

  return (
    <div>
      {user ? (
        <LoggedInAccount />
      ) : (
        <Button variant="shiny.white" onClick={loginModal.show}>
          Connect
        </Button>
      )}
    </div>
  );
};

export default SignIn;
