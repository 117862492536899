import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Popover,
  PopoverTrigger,
  Button,
  Avatar,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";

import centerEllipses from "~web/utils/text/centerEllipses";
import GradientAvatar from "~web/components/GradientAvatar";
import { useAuthContext } from "~web/state/auth";

const styles = {
  PopoverButton: {
    fontStyle: "15px",
    fontWeight: "semibold",
    lineHeight: "32px",
    width: "100%",
    height: "fit-content",
    borderRadius: "none",
    justifyContent: "flex-start",
    background: "transparent",
    paddingX: "0px",
    _hover: { opacity: 0.8, textDecoration: "none" },
    _active: { opacity: 0.6 },
  },
  PopoverIcon: {
    w: "14px",
    h: "14px",
  },
};

const LoggedInAccount: React.FunctionComponent = () => {
  const { logout, user } = useAuthContext();

  if (!user) {
    return null;
  }

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Button
          padding="4px 10px 4px 4px"
          _focus={{ boxShadow: "none" }}
          variant="shiny.white"
          gap="8px"
        >
          <Avatar
            width="22px"
            height="22px"
            ignoreFallback={false}
            icon={<GradientAvatar size={22} />}
          />
          {user.username ?? centerEllipses(user.address, 11)}
        </Button>
      </PopoverTrigger>
      <PopoverContent maxWidth="267px" borderRadius="12px" background="white">
        <PopoverBody
          display="flex"
          flexDirection="column"
          padding="8px 14px"
          alignItems="flex-start"
        >
          <Button
            sx={styles.PopoverButton}
            leftIcon={<ArrowForwardIcon sx={styles.PopoverIcon} />}
            onClick={logout}
          >
            Sign Out
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default LoggedInAccount;
