import { Icon } from "@chakra-ui/icons";

const MemIcon: React.FC = () => {
  return (
    <Icon
      viewBox="0 0 32 32"
      width="100%"
      height="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16Z"
        fill="#291E4B"
      />
      <path
        d="M21.6099 20.3086H24.4473V17.4713H21.6099V20.3086Z"
        fill="#D0FF6D"
      />
      <path
        d="M10.2608 20.3086H13.0981V17.4713H10.2608V20.3086Z"
        fill="#D0FF6D"
      />
      <path
        d="M18.7725 8.95947L21.6099 8.95947L21.6099 11.7968L18.7725 11.7968L18.7725 8.95947Z"
        fill="#C4C4C4"
      />
      <path
        d="M15.9351 20.3086H18.7725V17.4713H15.9351V20.3086Z"
        fill="#C4C4C4"
      />
      <path
        d="M13.0977 8.95947L15.9351 8.95947L15.9351 11.7968L13.0977 11.7968L13.0977 8.95947Z"
        fill="#C4C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7726 8.95947H21.6099V11.7968L18.7726 11.7968V8.95947ZM24.4473 11.7965V8.95958H21.61V11.7965V11.7969V14.6338H24.4473V11.7969V11.7965ZM13.0982 14.6338H10.2609V11.7969V11.7965V8.95958H13.0978V8.95947H15.9351V11.7968L13.0982 11.7968V11.7969V14.6338ZM21.61 20.3086H24.4473V17.4713H21.61V20.3086ZM13.0982 20.3086H10.2609V17.4713H13.0982V20.3086ZM21.61 17.4712H24.4473V14.6339H21.61V17.4712ZM13.0982 17.4712H10.2609V14.6339H13.0982V17.4712ZM18.7725 14.6338H15.9352V11.7969V11.7965V8.95958H18.7725V11.7965V11.7969V14.6338ZM15.9352 20.3086H18.7725V17.4713H15.9352V20.3086ZM15.9352 17.4712H18.7725V14.6339H15.9352V17.4712Z"
        fill="#D0FF6D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6106 11.7969H18.7733V14.6341V14.6342V17.4715H21.6106V14.6342V14.6341V11.7969ZM10.2612 17.4715V14.6342V14.6341V11.7969H7.42383V14.6341V14.6342V17.4715H10.2612ZM13.0985 23.1461H10.2613V20.3088H13.0985V17.4716H15.9359V20.3088H18.7731V23.1461H15.9359V23.1462H13.0985V23.1461ZM7.42383 17.4716H10.2612V20.3088V20.3089V23.1462H7.42383V20.3089V20.3088V17.4716ZM18.7733 20.3089V23.1462H21.6106V20.3089V20.3088V17.4716H18.7733V20.3088V20.3089ZM15.9359 14.6342V17.4715H13.0985V14.6342V14.6341V11.7969H15.9359V14.6341V14.6342Z"
        fill="url(#paint0_linear_10_14)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10_14"
          x1="14.5172"
          y1="11.7969"
          x2="14.5172"
          y2="23.1462"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#863AE8" />
          <stop offset="0.940802" stopColor="#4469C7" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
export default MemIcon;
