import {
  Box,
  IconButton,
  Avatar,
  Divider,
  Link,
  Tooltip,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { gql, useQuery } from "@apollo/client";
import NextLink from "next/link";

import MemIcon from "~web/components/icons/MemIcon";
import { SideNavQuery } from "~web/generated/graphql";

const SIDE_NAV_QUERY = gql`
  query SideNav {
    userCommunities {
      name
      imageUrl
      urlSlug
    }
  }
`;

const SideNav: React.FC = () => {
  const { data } = useQuery<SideNavQuery>(SIDE_NAV_QUERY);

  const communities = data?.userCommunities;

  return (
    <Box
      as="aside"
      display={["none", "none", "flex"]}
      gridColumnStart={1}
      gridColumnEnd={2}
      gridRowStart={1}
      gridRowEnd={3}
      flexDirection="column"
      alignItems="center"
      padding="18px"
      maxWidth="64px"
      width="100%"
      background="background"
      boxShadow="1px 0px #291e4b1a"
      position="fixed"
      top="0"
      bottom="0"
      overflow="hidden"
      zIndex="999"
    >
      <NextLink href="/">
        <IconButton
          aria-label="MEM"
          size="xs"
          marginBottom="16px"
          width="32px"
          height="32px"
          borderRadius="full"
          icon={<MemIcon />}
        />
      </NextLink>
      <Divider background="gray.400" marginBottom="16px" height="2px" />

      {communities?.map((community) => (
        <NextLink
          key={community.urlSlug}
          href={`/community/${community.urlSlug}`}
          passHref
        >
          <Link _focus={{ boxShadow: "none" }}>
            <Tooltip label={community.name} hasArrow placement="right">
              <IconButton
                aria-label={community.name}
                size="xs"
                marginBottom="16px"
                width="28px"
                height="28px"
                borderRadius="full"
                icon={
                  <Avatar
                    maxWidth="18px"
                    width="28px"
                    height="28px"
                    minW="28px"
                    name={community.name}
                    src={community?.imageUrl || ""}
                  />
                }
              />
            </Tooltip>
          </Link>
        </NextLink>
      ))}

      <Link as={NextLink} href="/communities">
        <IconButton
          aria-label="Add Community"
          width="28px"
          height="28px"
          padding="0"
          minW="0"
          marginBottom="16px"
          background="background"
          boxShadow="inset 0 0 0 1.4px var(--chakra-colors-gray-600)"
          color="gray.600"
          icon={<AddIcon w="12px" h="12px" />}
        />
      </Link>
    </Box>
  );
};

export default SideNav;
