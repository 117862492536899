import { Box } from "@chakra-ui/layout";

const GradientAvatar: React.FC<{ size?: number | string }> = ({
  size = 15,
}) => {
  return (
    <Box
      width={size}
      height={size}
      background="linear-gradient(180deg, #5671FF 0%, #2400FF 100%)"
      boxShadow="inset 0px -6px 12px rgba(255, 255, 255, 0.5)"
      borderRadius="full"
    />
  );
};
export default GradientAvatar;
