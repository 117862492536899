import {
  Button,
  IconButton,
  Flex,
  Divider,
  Link,
  Box,
  Avatar,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { ArrowForwardIcon, SearchIcon } from "@chakra-ui/icons";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useRouter } from "next/router";
import { gql, useQuery } from "@apollo/client";

import SignIn from "~web/components/SignIn";
import MemIcon from "~web/components/icons/MemIcon";
import { HeaderQuery } from "~web/generated/graphql";

const HEADER_QUERY = gql`
  query Header {
    userCommunities {
      id
      name
      urlSlug
      imageUrl
    }
  }
`;

const Header: React.FC = () => {
  const router = useRouter();
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);

  const { data } = useQuery<HeaderQuery>(HEADER_QUERY);

  const communities = data?.userCommunities;

  const handleMobileClick = (e: any, route: string) => {
    e.preventDefault();
    router.push(route);
    setDisplayMobileMenu(false);
  };

  return (
    <Flex
      as="header"
      backgroundColor="gray.200"
      alignItems="center"
      marginBottom="20px"
      padding="16px"
      justifyContent="space-between"
      position="fixed"
      left={["0px", "0px", "65px"]}
      right="0px"
      zIndex="1000"
    >
      <IconButton
        display={["inline-flex", "inline-flex", "none"]}
        aria-label="Open Menu"
        size="xs"
        width="32px"
        height="32px"
        marginLeft="0px"
        fontSize="24px"
        borderRadius="4px"
        background="transparent"
        _hover={{ opacity: 0.8 }}
        _active={{ opacity: 0.6 }}
        icon={<HamburgerIcon />}
        onClick={() => setDisplayMobileMenu(true)}
      />

      {/* Mobile Nav */}
      <Flex
        as="nav"
        position="fixed"
        overflowY="scroll"
        width="100%"
        minHeight="100vh"
        top="0"
        left="0"
        background="white"
        zIndex="1000"
        flexDirection="column"
        display={displayMobileMenu ? ["flex", "flex", "none"] : "none"}
        padding="16px"
      >
        <Flex justifyContent="space-between" marginBottom="18px">
          <Flex gap="16px">
            <IconButton
              aria-label="Close Menu"
              size="xs"
              width="32px"
              height="32px"
              marginLeft="0px"
              fontSize="20px"
              borderRadius="4px"
              background="transparent"
              _hover={{ opacity: 0.8 }}
              _active={{ opacity: 0.6 }}
              icon={<CloseIcon color="gray.900" />}
              onClick={() => setDisplayMobileMenu(false)}
            />

            <IconButton
              aria-label="MEM"
              size="xs"
              width="32px"
              height="32px"
              icon={<MemIcon />}
              onClick={(e) => handleMobileClick(e, "/")}
            />
          </Flex>
        </Flex>

        <Button
          variant="shiny.blue"
          marginBottom="10px"
          onClick={(e) => handleMobileClick(e, "/ask")}
        >
          Post Question
        </Button>

        <Button
          variant="shiny.white"
          color="gray.700"
          marginBottom="10px"
          leftIcon={<SearchIcon color="gray.650" />}
          onClick={(e) => handleMobileClick(e, "/")}
        >
          Search
        </Button>

        <Box color="gray.600" fontWeight="semibold" mt="18px">
          <NextLink href="/">
            <a>
              Explore <ArrowForwardIcon />
            </a>
          </NextLink>
        </Box>

        <Divider
          marginY="20px"
          orientation="horizontal"
          bg="gray.400"
          opacity="1"
          height="1px"
        />

        <Box>
          <Flex
            justifyContent="space-between"
            mb="18px"
            color="gray.600"
            fontSize="15px"
            fontWeight="semibold"
          >
            <Box>Communities</Box>

            <NextLink href="/communities">
              <a>
                Join more <ArrowForwardIcon />
              </a>
            </NextLink>
          </Flex>

          <Flex flexDirection="column" gap="12px" alignItems="stretch">
            {communities?.map((community) => (
              <Flex gap="8px" key={community.urlSlug}>
                <Avatar
                  height="24px"
                  width="24px"
                  name={community.name}
                  src={community.imageUrl || undefined}
                />
                <Link
                  fontWeight="semibold"
                  fontSize="15px"
                  lineHeight="24px"
                  width="100%"
                  maxWidth="100%"
                  color="gray.900"
                  overflow="hidden !important"
                  textOverflow="ellipsis !important"
                  height="fit-content"
                  noOfLines={1}
                  _focus={{ boxShadow: "none" }}
                  _hover={{ opacity: 0.8, textDecoration: "none" }}
                  _active={{ opacity: 0.6 }}
                  title={community.name}
                  onClick={(e) =>
                    handleMobileClick(e, `/community/${community.urlSlug}`)
                  }
                >
                  {community.name}
                </Link>
              </Flex>
            ))}
          </Flex>
        </Box>
      </Flex>

      {/* Desktop Nav */}
      <Flex
        as="nav"
        justifyContent="flex-end"
        width="100%"
        gap="12px"
        alignItems="center"
      >
        <NextLink href="/ask">
          <Button
            display={["none", "none", "inline-block"]}
            variant="shiny.blue"
          >
            Post Question
          </Button>
        </NextLink>

        <SignIn />
      </Flex>
    </Flex>
  );
};

export default Header;
